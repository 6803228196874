import lottie from 'lottie-web'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CustomEase } from 'gsap/CustomEase'
import { GSDevTools } from 'gsap/GSDevTools'
import { SplitText } from 'gsap/SplitText'

import { $, $$, cssVal, randomId, spOnly } from '../_global.js'
import ScrollBooster from 'scrollbooster'

import meritJson1 from '../json/merit1.json'
import meritJson2 from '../json/merit2.json'
import loading from '../json/loading.json'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase, GSDevTools, SplitText)

class App {
  constructor() {
    if ($('[page-top]')) {
      this.setFirstSliderNum()
      if (sessionStorage.getItem('isLoaded') !== 'true') this.setFirstAnime()
      this.utilSlider()
      this.featureDirection()
      this.mechanismAutoPlay()
      this.meritAnime()
      this.desingDirection()
      this.worksSlider()
      this.testInShow()
    }
    this.loading()
  }
  loading() {
    if (sessionStorage.getItem('isLoaded') === 'true' || location.pathname !== '/') {
      // sessionStorage.setItem('isLoaded', 'true')
      $('.base-loading').remove()
      if (location.pathname === '/') this.firstSlideAnime()
      return
    }

    if (window.ScrollSmoother) {
      // console.log(window.ScrollSmoother)
      window.ScrollSmoother.paused(true)
    } else {
      $('html').classList.add('index')
    }

    const anime = lottie.loadAnimation({
      container: $('.base-loading__load'),
      renderer: 'canvas',
      autoplay: true,
      loop: false,
      animationData: loading
    })
    anime.onComplete = () => {
      const tl = gsap.timeline()
      tl.add(() => {
        sessionStorage.setItem('isLoaded', 'true')

        if (window.ScrollSmoother) {
          window.ScrollSmoother.paused(false)
        } else {
          $('html').classList.remove('index')
        }
        this.firstSlideAnime()
      })
      // ロゴ出てくる
      tl.to('.base-loading .inline-logo', {
        opacity: 1,
        duration: 0.6
      })

      // ローディングを内包する要素自体が消える。
      tl.to(
        '.base-loading',
        {
          opacity: 0,
          duration: 2,
          delay: 0.3,
          ease: 'power2.in',
          onComplete: () => {
            $('.base-loading').remove()
          }
        },
        'hide'
      )
    }
  }
  setFirstAnime() {
    const titleSplit = new SplitText($('.first__title'), {
      type: 'words',
      tag: 'span',
      wordsClass: 'first__title--word'
    })
    gsap.set('.base-header__logo, .base-header__list__item, .base-header__menu-button', {
      opacity: 0
    })
    gsap.set(titleSplit.words, {
      x: '40',
      opacity: 0
    })
    gsap.set('.first__sub-title', {
      x: '40',
      opacity: 0
    })
    gsap.set('.first__contents', {
      opacity: 0,
      y: '100%'
    })
    gsap.set('.first__progress', {
      y: '100%',
      clipPath: 'inset(0 0 100% 0)'
    })
  }
  firstAnime() {
    const tl = gsap.timeline()

    console.log($$('.first__title--word'))
    tl.to('.base-header__logo, .base-header__list__item, .base-header__menu-button', {
      opacity: 1,
      stagger: 0.1,
      duration: 1
    })
    tl.to('.first__contents', {
      opacity: 1,
      y: 0,
      duration: 3.5,
      ease: CustomEase.create('custom', 'M0,0 C0.256,0 0.206,0.434 0.306,0.77 0.37,0.986 0.626,1 1,1 ')
    })
    tl.to(
      '.first__progress',
      {
        y: '0%',
        duration: 1.2,
        ease: 'expo.out',
        clipPath: 'inset(0 0 0% 0)'
      },
      '-=0.8'
    )
    tl.to(
      '.first__title--word, .first__sub-title',
      {
        x: 0,
        opacity: 1,
        stagger: 0.06,
        duration: 1,
        ease: 'power3.out'
      },
      '-=2.2'
    )

    // GSDevTools.create({
    //   animation: tl
    // })
  }
  setFirstSliderNum() {
    const firstContentsTopNum = $('.first__contents').getBoundingClientRect().top
    $('.first__contents').style.setProperty('--top-num', `${firstContentsTopNum}px`)

    window.addEventListener('resize', () => {
      if (spOnly) return
      const firstContentsTopNum = $('.first__contents').getBoundingClientRect().top
      $('.first__contents').style.setProperty('--top-num', `${firstContentsTopNum}px`)
    })
  }
  firstSlideAnime() {
    this.firstAnime()

    const countElArray = $$('.first__progress__num span')
    const contentsArray = $$('.first__contents__item-wrap')

    const tl = gsap.timeline({
      repeat: -1,
      delay: 2
    })

    // GSDevTools.create({
    //   animation: tl
    // })

    countElArray.forEach((el, index) => {
      const countEl = el
      const countNextEl = countElArray[index + 1] ? countElArray[index + 1] : countElArray[0]
      const contentsEl = contentsArray[index]
      const contentNextEl = contentsArray[index + 1] ? contentsArray[index + 1] : contentsArray[0]
      const id = randomId()

      tl.set('.first__progress__bar-child', {
        clipPath: 'inset(0 100% 0 0%)'
      })
      tl.to('.first__progress__bar-child', {
        clipPath: 'inset(0 0% 0 0%)',
        duration: 5,
        ease: 'none'
      })

      tl.set(
        contentNextEl,
        {
          height: '0%'
        },
        `hide_${id}`
      )
      tl.set(
        contentsEl,
        {
          zIndex: 1
        },
        `hide_${id}`
      )
      tl.set(contentNextEl, {
        zIndex: 10
      })
      tl.set($('.first__contents__item', contentNextEl), {
        y: '5%',
        scale: 1
      })
      tl.to(
        contentNextEl,
        {
          startAt: {
            height: '0%'
          },
          height: '100%',
          duration: 0.6,

          ease: CustomEase.create('custom', 'M0,0,C0.64,0.436,0.492,1,1,1')
        },
        `hide_${id}`
      )
      tl.to(
        $('.first__contents__item', contentNextEl),
        {
          y: '0%',
          duration: 1.1,
          ease: CustomEase.create('custom', 'M0,0,C0.64,0.436,0.492,1,1,1')
        },
        `hide_${id}`
      )
      tl.to(
        $('.first__contents__item', contentNextEl),
        {
          scale: 1.1,
          duration: 1.8
        },
        `hide_${id}-=0.7`
      )

      tl.to(
        '.first__progress__bar-child',
        {
          clipPath: 'inset(0 0% 0 100%)',
          duration: 1,
          ease: 'none'
        },
        `hide_${id}`
      )

      tl.to(
        countEl,
        {
          clipPath: 'inset(100% 0 0% 0)',
          y: '-100%',
          duration: 1,
          ease: 'power3.out'
        },
        `hide_${id}`
      )
      tl.set(countEl, {
        clipPath: 'inset(0% 0 100% 0)',
        y: '100%'
      })

      tl.to(
        countNextEl,
        {
          clipPath: 'inset(0% 0 0% 0)',
          y: '0%',
          duration: 1,
          ease: 'power3.out'
        },
        '-=1'
      )
    })
  }
  utilSlider() {
    if ($('.util .section-title') === null) return
    $('.util').style.setProperty('--title-width', $('.util .section-title').getBoundingClientRect().width + 'px')
    window.addEventListener('resize', () => {
      $('.util').style.setProperty('--title-width', $('.util .section-title').getBoundingClientRect().width + 'px')
    })
    const utilSlider = $('.util__gallery__wrap')
    const utilSliderWidth = utilSlider.getBoundingClientRect().width
    const moveNum = window.innerWidth - utilSliderWidth
    const wh = window.innerHeight
    gsap.to(utilSlider, {
      x: moveNum,
      ease: 'none',
      scrollTrigger: {
        trigger: '.util__gallery__wrap',
        scrub: true,
        start: `top top+=${wh}`,
        end: `bottom top`
      }
    })
  }
  featureDirection() {
    if (spOnly) return

    const imageHeight = $('.feature__gallery__item-wrap').getBoundingClientRect().height
    const wh = window.innerHeight
    $('.feature').style.setProperty('--padding-num', `${(wh - imageHeight) / 2}px`)
    window.addEventListener('resize', () => {
      const imageHeight = $('.feature__gallery__item-wrap').getBoundingClientRect().height
      const wh = window.innerHeight
      $('.feature').style.setProperty('--padding-num', `${(wh - imageHeight) / 2}px`)
    })
    ScrollTrigger.create({
      trigger: '.feature',
      pin: '.feature__front',
      pinSpacing: false,
      start: 'top top',
      end: 'bottom bottom'
    })
    const textBoxArray = $$('.feature__item')
    const imageArray = $$('.feature__gallery__item-wrap')
    const halfHeight = window.innerHeight / 2

    const headerHeight = $('.base-header').getBoundingClientRect().bottom
    imageArray.forEach((el, index) => {
      const textBoxEl = textBoxArray[index]
      textBoxEl.setAttribute('pos-status', index === 0 ? 'center' : 'down')
      ScrollTrigger.create({
        trigger: el,
        // markers: true,
        start: `top top+=${halfHeight - headerHeight}`,
        end: `bottom+=32 top+=${halfHeight - headerHeight}`,
        onEnter: () => {
          // console.log('onEnter', index)
          textBoxEl.setAttribute('pos-status', 'center')
        },
        onEnterBack: () => {
          // console.log('onEnterBack', index)
          textBoxEl.setAttribute('pos-status', 'center')
        },
        onLeave: () => {
          // console.log('onLeave', index)
          if (index === imageArray.length - 1) return
          textBoxEl.setAttribute('pos-status', 'up')
        },
        onLeaveBack: () => {
          // console.log('onLeaveBack', index)
          textBoxEl.setAttribute('pos-status', index === 0 ? 'center' : 'down')
        }
      })
    })
  }
  mechanismAutoPlay() {
    $$('.mechanism__kv__contents').forEach((videoEl) => {
      ScrollTrigger.create({
        trigger: '.mechanism',
        start: 'top center',
        end: 'top bottom',
        onEnter: () => {
          videoEl.style.opacity = 1
          videoEl.play()
        },
        onEnterBack: () => {
          if (spOnly) return
          gsap.to(videoEl, {
            opacity: 0,
            duration: 0.3,
            onComplete: () => {
              videoEl.pause()
              videoEl.currentTime = 0
            }
          })
        }
      })
    })
  }
  meritAnime() {
    // console.log('meritAnime', lottie)
    lottie.loadAnimation({
      container: $('.merit-main .merit__kv'),
      renderer: 'canvas',
      loop: true,
      autoplay: true,
      animationData: meritJson1
    })
    lottie.loadAnimation({
      container: $('.merit-sub .merit__kv'),
      renderer: 'canvas',
      loop: true,
      autoplay: true,
      animationData: meritJson2
    })
  }
  desingDirection() {
    if (spOnly) return
    ScrollTrigger.create({
      trigger: '.design',
      pin: '.design__front',
      pinSpacing: false,
      start: 'top top',
      end: 'bottom bottom'
    })
  }
  worksSlider() {
    new ScrollBooster({
      viewport: $('.works__gallery-slider'),
      content: $('.works__gallery'),
      direction: 'horizontal',
      scrollMode: 'native'
    })
  }
  testInShow() {
    gsap.set('.test__window', {
      clipPath: 'inset(0 100% 0 0)'
    })

    gsap.to('.test__window', {
      clipPath: 'inset(0 0% 0 0)',
      duration: 1,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: '.test__window',
        start: 'top center'
      }
    })
  }
}

export default () => {
  new App()
}
