import lottie from 'lottie-web'
import barba from '@barba/core'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CustomEase } from 'gsap/CustomEase'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { $, $$, cssVal, spOnly, device } from '../_global.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase, ScrollSmoother)

class App {
  constructor() {
    this.scrollSmooth()
    this.changeHeaderColor()
    this.toggleMenu()
    this.ctaShow()
    this.pageTransition()
  }

  scrollSmooth() {
    if (spOnly) return
    if (device() === 'tablet') return
    window.ScrollSmoother = ScrollSmoother.create({
      ease: 'expo.out',
      wrapper: '.default',
      content: '.default-wrap',
      smooth: 0.9,
      effects: true,
      smoothTouch: 0.1,
      normalizeScroll: false,
      ignoreMobileResize: true
    })
    ScrollTrigger.refresh()
  }
  changeHeaderColor() {
    if (spOnly) return
    const changeHeaderColorAttrs = $$('[change-header-color]')
    const header = $('.base-header')
    changeHeaderColorAttrs.forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        // markers: true,
        start: `top top+=${header.getBoundingClientRect().bottom}`,
        onEnter: () => {
          header.setAttribute('change-color', '')
        },
        onEnterBack: () => {
          header.setAttribute('change-color', '')
        },
        onLeave: () => {
          header.removeAttribute('change-color')
        },
        onLeaveBack: () => {
          header.removeAttribute('change-color')
        }
      })
    })
  }
  toggleMenu() {
    $('.base-header__menu-button').addEventListener('click', () => {
      $('.base-header').toggleAttribute('menu-open')
      if ($('.base-header').hasAttribute('menu-open')) {
        open()
      } else {
        close()
      }
    })

    const open = () => {
      const tl = gsap.timeline()
      tl.set('.default', {
        pointerEvents: 'none'
      })
      tl.set('.base-header__menu__nav-item', {
        y: '100%',
        clipPath: 'inset(0% 0 100% 0)'
      })
      tl.set('.base-header__menu__social, .base-header__menu__copy', {
        opacity: 0
      })
      tl.to('.base-header__menu', {
        opacity: 1,
        pointerEvents: 'auto',
        duration: 0.5
      })
      tl.to('.base-header__menu__nav-item', {
        y: '0%',
        clipPath: 'inset(0% 0 0% 0)',
        duration: 0.5,
        ease: 'power3',
        stagger: 0.1
      })
      tl.to(
        '.base-header__menu__social',
        {
          opacity: 1,
          duration: 0.5,
          ease: 'power1'
        },
        '-=0.4'
      )
      tl.to(
        '.base-header__menu__copy',
        {
          opacity: 1,
          duration: 0.5,
          ease: 'power1'
        },
        '-=0.4'
      )
    }
    const close = () => {
      const tl = gsap.timeline()
      tl.set('.default', {
        pointerEvents: 'auto'
      })
      tl.to(
        '.base-header__menu__nav-item',
        {
          y: '-100%',
          clipPath: 'inset(100% 0 0% 0)',
          duration: 0.5,
          ease: 'power3'
        },
        'hide'
      )
      tl.to(
        '.base-header__menu__social, .base-header__menu__copy',
        {
          opacity: 0,
          duration: 0.5,
          ease: 'power1'
        },
        'hide'
      )
      tl.to('.base-header__menu', {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.5
      })
    }
  }
  ctaShow() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 20) {
        $('.base-cta').setAttribute('cta-show', '')
      } else {
        $('.base-cta').removeAttribute('cta-show')
      }
    })
  }
  pageTransition() {
    // gsap.to(".base-header", {
    //   duration: 0.5,
    //   opacity: 1,
    // });
    // セッションストレージのisLoadがtrueのとき
    console.log(sessionStorage.getItem('isLoaded'))
    if (location.pathname !== '/' || sessionStorage.getItem('isLoaded') === 'true') {
      console.log('inのアニメ')
      gsap.to('body', {
        opacity: 1,
        duration: 0.5,
        delay: 1
      })
    } else {
      gsap.set('body', {
        opacity: 1
      })
    }
    $$('a:not([target])').forEach((el) => {
      if (el.href === location.href) return
      if (navigator.userAgent.match(/.(Googlebot|msnbot)./gi)) return
      const link = el.href
      el.onclick = (e) => {
        e.preventDefault()
        console.log(e)
        if (e.ctrlKey || e.metaKey) {
          window.open(el.getAttribute('href'), '_blank')
          return
        }
        $('body').removeAttribute('header-white')
        const tl = gsap.timeline()
        tl.to('body', {
          duration: 0.5,
          opacity: 0,
          onComplete: () => {
            location.href = link
          }
        })
      }
    })
  }
}

export default () => {
  new App()
}
