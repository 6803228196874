import lottie from 'lottie-web'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CustomEase } from 'gsap/CustomEase'
import { $, $$, cssVal, randomId, spOnly } from '../_global.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase)

class App {
  constructor() {
    if ($('[page-download]')) {
      // console.log('hi')
      this.selectPaper()
    }
  }
  selectPaper() {
    const documentInputEls = $$('[data-document-content-id], [data-test-data-id]')
    const array = []
    console.log(documentInputEls)
    documentInputEls.forEach((el) => {
      el.addEventListener('change', () => {
        const dataset = Object.fromEntries(Object.entries(el.dataset))
        if (el.checked) {
          array.push(dataset)
        } else {
          array.splice(array.indexOf(dataset), 1)
        }
        localStorage.setItem('selectDocument', JSON.stringify(array))
      })
    })
  }
}

export default () => {
  new App()
}
