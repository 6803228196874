import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CustomEase } from 'gsap/CustomEase'
import { $, $$, cssVal, randomId, spOnly } from '../_global.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase)

class App {
  constructor() {
    if ($('[page-download-thanks]')) {
      // console.log('hi')
      this.selectPaper()
    }
  }
  selectPaper() {
    // selectDocumentというローカルストレージを取得
    const selectDocument = JSON.parse(localStorage.getItem('selectDocument'))
    // console.log(selectDocument)
    const dataDocumentEls = Array.from($$('[data-document-content-id], [data-test-data-id]'))
    // console.log(dataDocumentEls)
    const array = selectDocument.forEach((item) => {
      // item.dataのキーを取得
      // console.log(item)
      const key = Object.keys(item)[0]
      const value = Object.values(item)[0]
      // console.log(key, value)

      // dataDocumentElsの中から、dataのキーとバリューの両方が一致するものを取得
      const dataDocumentEl = dataDocumentEls.find((el) => {
        return el.dataset[key] === value
      })
      console.log(dataDocumentEl)
      // console.log(dataDocumentEl)
      dataDocumentEl.style.display = 'block'
      dataDocumentEl.setAttribute('select-document', '')
    })
  }
}

export default () => {
  new App()
}
