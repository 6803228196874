import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CustomEase } from 'gsap/CustomEase'
import { $, $$, cssVal, randomId, spOnly } from '../_global.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase)

class App {
  constructor() {
    if ($('[page-faq]')) {
      this.setAnswerHeight()
    }
  }
  setAnswerHeight() {
    $$('.faq__item').forEach((item) => {
      item.style.setProperty('--answer-height', `${item.querySelector('.faq__item__column--answer').offsetHeight}px`)
      item.querySelector('.faq__item__column--answer').style.maxHeight = '0px'
      item.querySelector('.faq__item__column--question').addEventListener('click', () => {
        item.toggleAttribute('open')
      })
    })
  }
}

export default () => {
  new App()
}
