import barba from '@barba/core'
import common from './pages/_common.js'
import topPage from './pages/_index.js'
import downloadPage from './pages/_download.js'
import downloadThanksPage from './pages/_download-thanks.js'
import faqPage from './pages/_faq.js'

window.addEventListener('DOMContentLoaded', () => {
  // class App {
  //   constructor() {
  //     common()
  //     topPage()
  //     downloadPage()
  //     downloadThanksPage()
  //     faqPage()
  //     // this.pageTransition()
  //   }
  //   pageTransition() {
  //     const preventSettings = () => {
  //       // 外部リンクはtarget="_blank"に
  //       let site_url = location.protocol + '//' + location.host
  //       if (!href.startsWith(site_url)) {
  //         el.setAttribute('target', '_blank')
  //         return true
  //       }
  //       // アンカーリンクであり同一ページでなければbarbaを有効に
  //       let url = location.protocol + '//' + location.host + location.pathname
  //       let extract_hash = href.replace(/#.*$/, '')
  //       if (href.startsWith(location.protocol + '//' + location.host)) {
  //         if (href.indexOf('#') > -1 && extract_hash != url) {
  //           return false
  //         }
  //       }
  //       // 拡張子が該当する場合はtarget="_blank"に
  //       if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
  //         el.setAttribute('target', '_blank')
  //         return true
  //       }
  //       // 該当クラスに属していればBarbaを無効に
  //       let ignoreClasses = ['ab-item']
  //       ignoreClasses.forEach((cls) => {
  //         if (el.classList.contains(cls)) {
  //           return true
  //         }
  //       })
  //     }
  //     const delay = (n) => {}
  //       n = n || 2000;
  //       return new Promise((done) => {
  //         setTimeout(() => {
  //           done();
  //         }, n);
  //       });
  //     }
  //     barba.init({
  //       // prevent: preventSettings,
  //       transitions: [
  //         {
  //           async leave(data) {

  //             gsap.to(data.current.container, {
  //               opacity: 0,
  //               duration: 0.5
  //             })
  //           },
  //           enter(data) {
  //             const tl = gsap.timeline()
  //             tl.set(data.next.container, {
  //               opacity: 0
  //             })
  //             tl.to(data.next.container, {
  //               opacity: 1,
  //               duration: 0.5
  //             })
  //           }
  //         }
  //       ]
  //     })
  //   }
  // }
  // new App()
  common()
  topPage()
  downloadPage()
  downloadThanksPage()
  faqPage()
})
